import { Col, Row, Table } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useMemo } from 'react';
import { months } from '../../common/rootTypes';
import Dropdown from '../../components/Forms/Dropdown/Dropdown';
import { LeaderboardContext, LeaderboardResponse } from './context/LeaderboardProvider';
import {
  StyledMonthStatSection,
  StyledDesciption,
  LeaderboardContainer,
  StyledCrown,
  StyledRank,
  StyledScore,
} from './styles';
import { CrownFilled } from '@ant-design/icons';
import ThrophyImg from '../../images/throphy.png';
import ProfileColumn from '../../components/ProfileColumn';

const LeaderBoard = () => {
  const currentMonth = Number(moment().format('M'));
  const currentMonthvalue = useMemo(() => months.filter((month) => month.id === currentMonth), [currentMonth]);

  const { leaderboard, fetchLeaderboard, fetchLeaderboardStatus } = useContext(LeaderboardContext);

  const columns = [
    {
      title: 'RANK',
      key: 'rank',
      render: (record: LeaderboardResponse) => {
        if (leaderboard.indexOf(record) === 0) {
          return (
            <>
              <StyledRank>{leaderboard.indexOf(record) + 1}</StyledRank>
              <StyledCrown positon="first">
                <CrownFilled />
              </StyledCrown>
            </>
          );
        } else if (leaderboard.indexOf(record) === 1) {
          return (
            <>
              <StyledRank>{leaderboard.indexOf(record) + 1}</StyledRank>
              <StyledCrown positon="second">
                <CrownFilled />
              </StyledCrown>
            </>
          );
        } else if (leaderboard.indexOf(record) === 2) {
          return (
            <>
              <StyledRank>{leaderboard.indexOf(record) + 1}</StyledRank>
              <StyledCrown positon="third">
                <CrownFilled />
              </StyledCrown>
            </>
          );
        } else {
          return (
            <>
              <StyledRank>{leaderboard.indexOf(record) + 1}</StyledRank>
            </>
          );
        }
      },
    },
    {
      title: 'NAME',
      key: 'name',
      render: (record: LeaderboardResponse) => (
        <ProfileColumn
          name={`${record.firstName} ${record.lastName}`}
          profilePicture={record.profilePicture}
          email={record.email}
        />
      ),
    },
    {
      title: 'POINTS',
      key: 'points',
      render: (record: LeaderboardResponse) => <StyledScore>{Math.ceil(record.value)}</StyledScore>,
    },
  ];

  useEffect(() => {
    fetchLeaderboard && fetchLeaderboard(Number(currentMonthvalue[0].id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LeaderboardContainer>
      <Row>
        <Col span={2}>
          <img src={ThrophyImg} />
        </Col>
        <Col span={22}>
          <StyledMonthStatSection>
            <div className="contentTitle">Leaderboard of</div>
            <div className="dropdown-month">
              <Dropdown
                name="month"
                items={months}
                defaultValue={currentMonthvalue[0].text}
                onChange={(e) => {
                  // getStats && getStats(Number(e));
                  // getMonthChallenges && getMonthChallenges(Number(e));
                  fetchLeaderboard && fetchLeaderboard(Number(e));
                }}
              />
            </div>
          </StyledMonthStatSection>
          <StyledDesciption>
            Overall leaderboard of the month based on the performsnce of all the challenges, burned calories, and
            consistency. Leaderboard will be reset at the beginning of every month.{' '}
          </StyledDesciption>
          <StyledDesciption>
            Note: Free gift/s related to your subscription will be awarded based on this leaderboard.
          </StyledDesciption>
          {leaderboard.length > 0 && <Table dataSource={leaderboard} columns={columns} />}
        </Col>
      </Row>
    </LeaderboardContainer>
  );
};

export default LeaderBoard;
