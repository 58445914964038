import { CardElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Space } from 'antd';
import { useEffect } from 'react';
import SimpleButton from '../../../components/buttons/SimpleButton/SimpleButton';
import { StyledContainer, StyledPayment } from './styles';

interface ICheckoutForm {
  prev: () => void;
}

const CheckoutForm = ({ prev }: ICheckoutForm) => {
  const stripe = useStripe();
  const elements = useElements();

  const submitPayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_HOSTED_URL}/onboard/2`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <StyledContainer>
      <Form>
        <StyledPayment>
          <PaymentElement />
        </StyledPayment>

        {/* <CardElement /> */}
        <Space>
          <SimpleButton onClick={() => prev()} className="secondaryBtn">
            Back
          </SimpleButton>
          <SimpleButton type="primary" onClick={submitPayment} className={'primaryBtn'} disabled={!stripe}>
            Next
          </SimpleButton>
        </Space>
      </Form>
    </StyledContainer>
  );
};

export default CheckoutForm;
