import { FormInstance, Radio, RadioChangeEvent } from 'antd';
import {
  StyledOption,
  StyledPrice,
  StyledProductContainer,
  StyledProductDescription,
  StyledProductName,
} from './styles';
import { useEffect, useState } from 'react';
import { getInvitedProductDetailsApi } from '../../../api/axios-api';

interface IProducts {
  form: FormInstance;
}
type InvitedProductResponse = {
  productPriceId: string;
  users: number;
  pricePerUser: number;
};

const Product = ({ form }: IProducts) => {
  const [invitedProduct, setInvitedProduct] = useState<InvitedProductResponse | null>(null);

  const products = [
    {
      name: 'Essential',
      title: (
        <>
          <StyledPrice>{'USD 50'}</StyledPrice>/month
        </>
      ),
      subtitle: [
        // 'Unlimited users',
        'Pre-designed challenge templates',
        // 'Monthly gifts for the top performers',
        // '7 - days free trial',
        'Mobile app for the users',
        'Unlimited access to create custom challenges',
        'Report generation for insurance discounts',
      ],
      priceId: process.env.REACT_APP_PRODUCT_ESSENTIAL,
    },
    // {
    //   name: 'Standard',
    //   title: (
    //     <>
    //       <StyledPrice>{'USD 299'}</StyledPrice>/month
    //     </>
    //   ),
    //   subtitle: 'Unlimited fitness challenges with up to 150 users in your team.',
    //   priceId: process.env.REACT_APP_PRODUCT_STANDARD,
    // },
    {
      name: 'Enterprise',
      title: (
        <>
          <StyledPrice>{'Contact Us'}</StyledPrice>
        </>
      ),
      subtitle: ['For custom workflows and integrations, speak to an expert about our Enterprise Plan'],
      priceId: '-1',
    },
  ];
  const onChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({ product: e.target.value });
  };

  useEffect(() => {
    const fetchInivitedProduct = async () => {
      const response = await getInvitedProductDetailsApi();

      if (response.status == 200) {
        setInvitedProduct(response.data.body);
      }
    };

    fetchInivitedProduct();
  }, []);

  if (!invitedProduct) return <div>loading</div>;

  return (
    <StyledProductContainer>
      <Radio.Group onChange={onChange} defaultValue={form.getFieldValue('product')}>
        <StyledOption key={invitedProduct?.productPriceId}>
          <Radio value={invitedProduct?.productPriceId} checked={true}>
            <StyledProductName>{products[0].name}</StyledProductName>
            <StyledPrice>{`USD ${Number(invitedProduct.pricePerUser) * Number(invitedProduct.users)}`}</StyledPrice>
            /month
            <StyledProductDescription>
              <ul>
                {products[0].subtitle.map((sub: string, i: number) => (
                  <li key={i}>{sub}</li>
                ))}
              </ul>
            </StyledProductDescription>
          </Radio>
        </StyledOption>

        {/* {products.map((product) => (
          <StyledOption key={product.priceId}>
            <Radio value={product.priceId}>
              <StyledProductName>{product.name}</StyledProductName>
              {product.title}
              <StyledProductDescription>
                <ul>
                  {product.subtitle.map((sub: string, i: number) => (
                    <li key={i}>{sub}</li>
                  ))}
                </ul>
              </StyledProductDescription>
            </Radio>
          </StyledOption>
        ))} */}
      </Radio.Group>
    </StyledProductContainer>
  );
};

export default Product;
