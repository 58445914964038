import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Form, Space, Steps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleButton from '../../components/buttons/SimpleButton/SimpleButton';
import { OnboardContext } from './context/OnboardProvider';
import MultipleAdmin from './MultipleAdmin';
import Product from './Product';
import StepContent from './StepContent';
// import StepContent from './StepContent';
import { StyledContainer, StyledStepsTitle } from './styles';
import Subscription from './Subscription';
import TeamMembers from './TeamMembers';
const { Step } = Steps;

const Onboard = () => {
  const { step } = useParams();

  const { registerCustomer, subscribeCustomer, subscription, requestEnterprise } = useContext(OnboardContext);
  const [current, setCurrent] = useState<number>(Number(step) || 0);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (subscription && subscription.clientSecret) {
      next();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const submit = () => {
    const membersFile = form.getFieldValue('members_file');
    const membersTags = form.getFieldValue('members');

    const members = [...(membersFile || []), ...(membersTags || [])];
    registerCustomer &&
      registerCustomer({
        admins: form.getFieldValue('admins') || [],
        members: members,
      });
  };

  const subscribe = () => {
    const product = form.getFieldValue('product');
    if (product) {
      if (product === '-1') {
        requestEnterprise && requestEnterprise();
        navigate('/getback');
      } else {
        subscribeCustomer &&
          subscribeCustomer({
            productId: product,
          });
      }
    }
  };

  const steps = [
    {
      title: 'Step 1/4',
      content: (
        <StepContent
          title="Choose a plan"
          description="Enjoy the personalized solution designed just for you.
          To make any changes or enhancements, please contact our sales team. We're here to ensure your experience is optimized for success!"
        >
          <Product form={form} />
        </StepContent>
      ),
    },
    {
      title: 'Step 2/4',
      content: (
        <StepContent
          title="Start your FitClub today"
          description="Add creadit card details to continue. Cancel at any time, you won’t be charged during the first 7 days."
        >
          <div>
            <Subscription prev={prev} />
          </div>
        </StepContent>
      ),
    },
    {
      title: 'Step 3/4',
      content: (
        <StepContent
          title="Add multiple admins"
          description="Add up to 2 other admins by typing email addresses seperated with a comma."
        >
          <MultipleAdmin form={form} />
        </StepContent>
      ),
    },
    {
      title: 'Step 4/4',
      content: (
        <StepContent
          title="Invite your team"
          description="Add the list of memebers of your workplace. Integrate your HR system or add manually. Sync your team into FitClub, and automatically onboard and offboard employees. We will be sending a link for your team to get the app downloaded."
        >
          <TeamMembers form={form} />
        </StepContent>
      ),
    },
  ];

  return (
    <StyledContainer>
      <Form name="subscriptionForm" preserve={false} form={form}>
        <StyledStepsTitle>{`Step ${current + 1}/4`}</StyledStepsTitle>
        <Steps current={current} type="navigation" size="small">
          {steps.map((item, i) => (
            <Step key={item.title} status={'process'} className={current > i ? 'ant-steps-item-active' : ''} />
          ))}
        </Steps>

        <div>{steps[current].content}</div>

        <Space>
          {current > 2 && (
            <SimpleButton onClick={() => prev()} className="secondaryBtn">
              Back
            </SimpleButton>
          )}

          {current === 2 && (
            <SimpleButton onClick={() => next()} className="secondaryBtn">
              Skip for now
            </SimpleButton>
          )}

          {current < steps.length && current !== 1 && (
            <SimpleButton
              type="primary"
              onClick={() => {
                if (current === 0) {
                  subscribe();
                } else if (current === 3) {
                  submit();
                } else {
                  next();
                }
              }}
              className={'primaryBtn'}
            >
              Next
            </SimpleButton>
          )}
        </Space>
      </Form>
    </StyledContainer>
  );
};

export default Onboard;
