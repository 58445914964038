import { Col, DatePicker, Form, message, Row, Steps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { dateInUTCDateString } from '../../../common/utils';
import SimpleButton from '../../../components/buttons/SimpleButton/SimpleButton';
import Dropdown from '../../../components/Forms/Dropdown/Dropdown';
import FormLabel from '../../../components/Forms/FormLabel/FormLabel';
import TextField from '../../../components/Forms/TextField/TextField';
import AppModal from '../../../components/Modal/AppModal';
import ChallengeType from '../ChallengeType';
import { ChallengeContext, ChallengeMode, Template, WorkoutType } from '../context/ChallengeProvider';
import CreateChallengeHeader from '../CreateChallengeHeader';
import { StyledContainer, StyledDescription, StyledStepsTitle } from './styles';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, convertToRaw, EditorState, ContentBlock } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';

const { Step } = Steps;
const { RangePicker } = DatePicker;

const gutterh = 16;
const gutterv = 24;
const largeColspan = 12;
const xxlColSpan = 24;

export interface ICreateChallenge {
  isModalVisible: boolean;
  handleCancel: () => void;
  mode?: ChallengeMode;
}

const items = [
  { id: '10000', text: '10,000' },
  { id: '20000', text: '20,000' },
  { id: '30000', text: '30,000' },
  { id: '70000', text: '70,000' },
  { id: '100000', text: '100,000' },
  { id: '300000', text: '300,000' },
  { id: '500000', text: '500,000' },
];

const distanceItems = [
  { id: '2000', text: '2K' },
  { id: '5000', text: '5K' },
  { id: '15000', text: '15K' },
  { id: '35000', text: '35K' },
  { id: '105000', text: '105K' },
  { id: '150000', text: '150K' },
  { id: '250000', text: '250K' },
  { id: '450000', text: '450K' },
];

const CreateChallenge = ({ isModalVisible, handleCancel, mode = ChallengeMode.CREATE }: ICreateChallenge) => {
  const { templates, loadTemplates, addChallenge, selectedChallenge, updateChallenge, predefinedTemplates } =
    useContext(ChallengeContext);

  const [form] = Form.useForm();
  const [hasErrors, setHasErrors] = useState(false);
  const [current, setCurrent] = useState<number>(mode === ChallengeMode.CREATE ? 0 : 1);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [isCustom, setIsCustom] = useState<boolean>(false);

  const instructionBlocksFromHTML =
    mode === ChallengeMode.EDIT ? convertFromHTML(selectedChallenge?.instructions as string) : undefined;
  const [instructionsEditorState, setInstructionsEditorState] = useState(
    mode === ChallengeMode.CREATE
      ? () => EditorState.createEmpty()
      : EditorState.createWithContent(
          ContentState.createFromBlockArray(
            instructionBlocksFromHTML?.contentBlocks as ContentBlock[],
            instructionBlocksFromHTML?.entityMap
          )
        )
  );

  const rewardsBlocksFromHTML =
    mode === ChallengeMode.EDIT ? convertFromHTML(selectedChallenge?.rewards.rewards as string) : undefined;
  const [rewardsEditorState, setRewardsEditorState] = useState(
    mode === ChallengeMode.CREATE
      ? () => EditorState.createEmpty()
      : EditorState.createWithContent(
          ContentState.createFromBlockArray(
            rewardsBlocksFromHTML?.contentBlocks as ContentBlock[],
            rewardsBlocksFromHTML?.entityMap
          )
        )
  );

  const claimsBlocksFromHTML =
    mode === ChallengeMode.EDIT ? convertFromHTML(selectedChallenge?.rewards.claims as string) : undefined;
  const [claimsEditorState, setClaimsEditorState] = useState(
    mode === ChallengeMode.CREATE
      ? () => EditorState.createEmpty()
      : EditorState.createWithContent(
          ContentState.createFromBlockArray(
            claimsBlocksFromHTML?.contentBlocks as ContentBlock[],
            claimsBlocksFromHTML?.entityMap
          )
        )
  );

  useEffect(() => {
    loadTemplates && loadTemplates('walking');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = [
    {
      title: 'Step 1/4',
      content: (
        <CreateChallengeHeader title="Create a new challenge" description="Choose a type of exercise for the challenge">
          <ChallengeType
            form={form}
            handleSeletecteTemplate={(id: string, type: string) => {
              if (id) {
                const selectedTemplate = templates.filter((template) => template.id === id);
                const selectedPredefinedTemplate = predefinedTemplates[type as WorkoutType].filter(
                  (template) => template.id === id
                );

                const selected = selectedTemplate.length > 0 ? selectedTemplate : selectedPredefinedTemplate;

                setSelectedTemplate(selected[0]);
                const blocksFromHTML = convertFromHTML(selected[0].description);
                setInstructionsEditorState(
                  EditorState.createWithContent(
                    ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
                  )
                );
              } else {
                setSelectedTemplate(null);
              }
            }}
            handleCustom={setIsCustom}
          />
        </CreateChallengeHeader>
      ),
    },
    {
      title: 'Step 2/4',
      content: (
        <CreateChallengeHeader title="Create a new challenge" description="Choose a time period for the challenge">
          <Form
            form={form}
            initialValues={
              mode === ChallengeMode.EDIT
                ? {
                    challengeName: selectedChallenge?.name,
                    numberOfSteps: selectedChallenge?.workoutPoints.toString(),
                    dateRange: [moment(selectedChallenge?.startDate), moment(selectedChallenge?.endDate)],
                  }
                : undefined
            }
          >
            <Row gutter={[gutterh, gutterv]}>
              <Col span={xxlColSpan}>
                <TextField
                  name={'challengeName'}
                  label={'Challenge Name'}
                  required={true}
                  placeholder="Enter challenge name"
                  defaultValue={selectedTemplate?.name}
                />
              </Col>
            </Row>
            <Row gutter={[gutterh, gutterv]}>
              <Col span={largeColspan}>
                {(mode === ChallengeMode.CREATE &&
                  (form.getFieldValue('challengeType') === 'walking' ||
                    form.getFieldValue('challengeType') === undefined)) ||
                (mode === ChallengeMode.EDIT && selectedChallenge?.workoutType === 'walking') ? (
                  <Dropdown
                    name={'numberOfSteps'}
                    label={'Number of steps'}
                    items={items}
                    required={true}
                    valueType="string"
                    defaultValue={selectedTemplate?.workoutPoints.toString()}
                  ></Dropdown>
                ) : (
                  <Dropdown
                    name={'numberOfSteps'}
                    label={'Total distance'}
                    items={distanceItems}
                    required={true}
                    valueType="string"
                    defaultValue={selectedTemplate?.workoutPoints.toString()}
                  ></Dropdown>
                )}
              </Col>
            </Row>
            <Row gutter={[gutterh, gutterv]}>
              <Col span={largeColspan}>
                <FormLabel label={'Time Period for the challenge'} required={true}></FormLabel>
                <Form.Item
                  name={'dateRange'}
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                  required={true}
                >
                  <RangePicker />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </CreateChallengeHeader>
      ),
    },
    {
      title: 'Step 3/4',
      content: (
        <CreateChallengeHeader title="Create a new challenge" description="Challenge details">
          <Form form={form}>
            <Row gutter={[gutterh, gutterv]}>
              <Col span={xxlColSpan}>
                <TextField name={'challengeName'} label={'Challenge Name'} defaultValue={selectedTemplate?.name} />
              </Col>
            </Row>
            <Row gutter={[gutterh, gutterv]}>
              <Col span={xxlColSpan}>
                {/* <TextArea name={'instructions'} rows={5} label={'Instructions'} /> */}
                <Form.Item name={'instructions'}>
                  <FormLabel label={'Instructions'} required={true} />
                  <Editor
                    editorState={instructionsEditorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={setInstructionsEditorState}
                    toolbar={{
                      options: ['inline', 'list'],
                      inline: { inDropdown: false },
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </CreateChallengeHeader>
      ),
    },
    {
      title: 'Step 4/4',
      content: (
        <CreateChallengeHeader title="Create a new challenge" description="Incentives/ Rewards">
          <StyledDescription>
            {'Are you offering any incentives/ rewards for this challenge? Then, explain '}
          </StyledDescription>
          <Form form={form}>
            <Row gutter={[gutterh, gutterv]}>
              <Col span={xxlColSpan}>
                {/* <TextArea name={'rewards'} rows={5} label={'What are your incentives/ Rewards for the winner'} /> */}
                <Form.Item name={'rewards'}>
                  <FormLabel label={'What are your incentives/ Rewards for the winner'} required={false} />
                  <Editor
                    editorState={rewardsEditorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={setRewardsEditorState}
                    toolbar={{
                      options: ['inline', 'list'],
                      inline: { inDropdown: false },
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[gutterh, gutterv]}>
              <Col span={xxlColSpan}>
                {/* <TextArea name={'claims'} rows={5} label={'How the winner can claim the rewards?'} /> */}
                <Form.Item name={'claims'}>
                  <FormLabel label={'How the winner can claim the rewards?'} required={false} />
                  <Editor
                    editorState={claimsEditorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={setClaimsEditorState}
                    toolbar={{
                      options: ['inline', 'list'],
                      inline: { inDropdown: false },
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </CreateChallengeHeader>
      ),
    },
  ];

  const next = async () => {
    if (current === 0) {
      if (!isCustom && selectedTemplate == null) {
        message.error('Please select a template or create a custom challenge');
        setHasErrors(true);
      } else {
        setHasErrors(false);
        setCurrent(current + 1);
      }
    } else if (current === 2) {
      if (!instructionsEditorState.getCurrentContent().hasText()) {
        message.error('Instructions are mandatory');
        setHasErrors(true);
      } else {
        setHasErrors(false);
        setCurrent(current + 1);
      }
    } else {
      try {
        await form.validateFields();
        setCurrent(current + 1);
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
    }
  };

  const handleSubmit = () => {
    if (mode === ChallengeMode.CREATE) {
      addChallenge &&
        addChallenge({
          templateId: selectedTemplate !== null ? selectedTemplate.id : null,
          name: form.getFieldValue('challengeName') as string,
          workoutType:
            (form.getFieldValue('challengeType') as string) == undefined
              ? 'walking'
              : (form.getFieldValue('challengeType') as string),
          workoutPoints: Number(form.getFieldValue('numberOfSteps')),
          workoutUnit: form.getFieldValue('challengeType') === 'walking' ? 'steps' : 'distance',
          startTime: dateInUTCDateString(form.getFieldValue('dateRange')[0]),
          endTime: dateInUTCDateString(form.getFieldValue('dateRange')[1]),
          instructions: draftToHtml(convertToRaw(instructionsEditorState.getCurrentContent())),
          rewards: {
            rewards: draftToHtml(convertToRaw(rewardsEditorState.getCurrentContent())),
            claims: draftToHtml(convertToRaw(claimsEditorState.getCurrentContent())),
          },
        });
    } else {
      updateChallenge &&
        updateChallenge(
          {
            name: form.getFieldValue('challengeName') as string,
            workoutPoints: Number(form.getFieldValue('numberOfSteps')),
            workoutUnit: form.getFieldValue('challengeType') === 'walking' ? 'steps' : 'distance',
            startTime: dateInUTCDateString(form.getFieldValue('dateRange')[0]),
            endTime: dateInUTCDateString(form.getFieldValue('dateRange')[1]),
            instructions: draftToHtml(convertToRaw(instructionsEditorState.getCurrentContent())),
            rewards: {
              rewards: draftToHtml(convertToRaw(rewardsEditorState.getCurrentContent())),
              claims: draftToHtml(convertToRaw(claimsEditorState.getCurrentContent())),
            },
          },
          selectedChallenge?.challengeId || ''
        );
    }

    handleCancel();
  };

  return (
    <AppModal visible={isModalVisible} handleCancel={handleCancel} width={600} showButtons={false}>
      <StyledContainer>
        <Form form={form} name={'createChallenge'} preserve={false}>
          <StyledStepsTitle>{`Step ${current + 1}/4`}</StyledStepsTitle>
          <Steps current={current} type="navigation" size="small">
            {steps.map((item, i) => (
              <Step key={item.title} status={'process'} className={current > i ? 'ant-steps-item-active' : ''} />
            ))}
          </Steps>

          <div>{steps[current].content}</div>

          <Row gutter={[gutterh, gutterv]}>
            <Col span={largeColspan}>
              <SimpleButton onClick={handleCancel} className="secondaryBtn">
                Cancel
              </SimpleButton>
            </Col>

            {current < steps.length - 1 && (
              <Col span={largeColspan}>
                <SimpleButton
                  type="primary"
                  onClick={async () => {
                    await next();
                  }}
                  className={'primaryBtn'}
                >
                  Next
                </SimpleButton>
              </Col>
            )}

            {current === steps.length - 1 && (
              <Col span={largeColspan}>
                <SimpleButton type="primary" onClick={handleSubmit} className={'primaryBtn'}>
                  {mode === ChallengeMode.CREATE ? ' Start Challenge' : 'Update Challenge'}
                </SimpleButton>
              </Col>
            )}
          </Row>
        </Form>
      </StyledContainer>
    </AppModal>
  );
};

export default CreateChallenge;
